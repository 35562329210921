import React from "react";
import {Button} from "gatsby-theme-material-ui";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";

export default function HrefButton(props) {
  return (
    <HrefComponent
      component={Button}
      {...props}
    />
  );
}
