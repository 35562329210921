import {Link, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {PictureAsPdf} from "@material-ui/icons";
import React from "react";

export default function Downloads({documents, sessionToken}) {
  return (
    <>
      <List>
        {documents?.map((document, i) => (
          <ListItem key={document.uuid || i}>
            <ListItemIcon>
              <PictureAsPdf/>
            </ListItemIcon>
            <ListItemText
              primary={
                <Link href={`/api/teilnahme/documents/${document.uuid}/blob/?token=${sessionToken}`}>
                  {document.name}
                </Link>
              }
              secondary={document.description}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
