import {Button, Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader} from "@material-ui/core";
import {AccountBalanceWallet, AccountBox, AlternateEmail, Business, Person, Place} from "@material-ui/icons";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {PROFILE_SCHEMA} from "src/features/participation/participation-validation";
import {Choices, TextField} from "src/components/form";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {useHasMounted} from "src/packages/has-mounted-hook";

const useStyles = makeStyles((theme) => ({
  formList: {
    '& > li': {
      alignItems: 'flex-start',

      '& > div:first-child': {
        paddingTop: 20,
      },

      '&.MuiListSubheader-sticky': {
        background: 'white',
        zIndex: 2,
      },
    },
  },
}));

export default function UpdateContactDetails(
  {
    data,
    formErrors,
    isSubmitting,
    saveProfile,
    focusField,
    close,
    cancel,
    visible,
  },
) {
  const hasMounted = useHasMounted();

  const form = useForm({
    resolver: yupResolver(PROFILE_SCHEMA),
    defaultValues: {
      firmenname: data?.firmenname,
      adresse: data?.adresse,

      ansprechpartner_geschaeftsleitung_anrede: data?.ansprechpartner_geschaeftsleitung_anrede || '',
      ansprechpartner_geschaeftsleitung_titel: data?.ansprechpartner_geschaeftsleitung_titel,
      ansprechpartner_geschaeftsleitung_name: data?.ansprechpartner_geschaeftsleitung_name,
      ansprechpartner_geschaeftsleitung_email: data?.ansprechpartner_geschaeftsleitung_email,

      ansprechpartner_technik_anrede: data?.ansprechpartner_technik_anrede || '',
      ansprechpartner_technik_titel: data?.ansprechpartner_technik_titel,
      ansprechpartner_technik_name: data?.ansprechpartner_technik_name,
      ansprechpartner_technik_email: data?.ansprechpartner_technik_email,

      ansprechpartner_lira_anrede: data?.ansprechpartner_lira_anrede || '',
      ansprechpartner_lira_titel: data?.ansprechpartner_lira_titel,
      ansprechpartner_lira_name: data?.ansprechpartner_lira_name,
      ansprechpartner_lira_email: data?.ansprechpartner_lira_email,
    },
    shouldUnregister: false,
  });
  const {handleSubmit, setError, clearErrors, register, control, setValue, formState: {errors}} = form;
  const hasErrors = errors && Object.keys(errors).length > 0;

  const submitAction = useMemo(() => (event) => {
    event.preventDefault();
    return handleSubmit((validatedData) => {
      saveProfile(validatedData);
      close();
    })();
  }, [handleSubmit, saveProfile, close]);

  useEffect(() => {
    if (!formErrors) {
      // TODO: Reset form submit state.
      clearErrors();
      return;
    }
    Object.entries(formErrors).forEach(([field, error]) => {
      setError(field, {type: 'manual', message: error}, {shouldFocus: true});
    });
  }, [formErrors, clearErrors, setError, visible]);

  const classes = useStyles();

  // Allow focussing a specific field.
  const firmenname = useRef();
  const ansprechpartner_geschaeftsleitung_name = useRef();
  const ansprechpartner_geschaeftsleitung_email = useRef();
  const ansprechpartner_technik_name = useRef();
  const ansprechpartner_technik_email = useRef();
  const ansprechpartner_lira_name = useRef();
  const ansprechpartner_lira_email = useRef();

  const focusableFields = useMemo(() => ({
    firmenname,
    ansprechpartner_geschaeftsleitung_name,
    ansprechpartner_geschaeftsleitung_email,
    ansprechpartner_technik_name,
    ansprechpartner_technik_email,
    ansprechpartner_lira_name,
    ansprechpartner_lira_email,
  }), [
    firmenname,
    ansprechpartner_geschaeftsleitung_name,
    ansprechpartner_geschaeftsleitung_email,
    ansprechpartner_technik_name,
    ansprechpartner_technik_email,
    ansprechpartner_lira_name,
    ansprechpartner_lira_email,
  ]);

  const [lastFocusField, setLastFocusField] = useState(null);

  useEffect(() => {
    if (lastFocusField === focusField) {
      return;
    }

    const field = focusableFields[focusField];
    if (field?.current) {
      try {
        field.current.querySelectorAll('input').forEach((el) => {
          const {autocomplete} = el;
          el.autocomplete = null; // Disable autocomplete temporarily.
          el.focus();
          el.autocomplete = autocomplete;
        });
      } catch (e) {
        // Nothing bad happens if forEach() is not supported by the browser.
        console.error(e);
      }
    }

    setLastFocusField(focusField);
  }, [focusField, focusableFields, lastFocusField, setLastFocusField]);

  if (!hasMounted) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={submitAction}>
        <List className={classes.formList}>
          <ListItem>
            <ListItemIcon>
              <Business/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.firmenname}
                  label="Firmenname"
                  name="firmenname"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="company-name"
                />
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Place/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  rows={3}
                  multiline
                  label="Adresse"
                  name="adresse"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="address"
                />
              )}
            />
          </ListItem>

          <ListSubheader>Ansprechpartner Geschäftsleitung</ListSubheader>

          <ListItem>
            <ListItemIcon/>
            <ListItemText
              primary={(
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Choices
                        name="ansprechpartner_geschaeftsleitung_anrede"
                        label="Anrede"
                        errors={errors}
                        control={control}
                        items={{
                          frau: "Frau",
                          herr: "Herr",
                          '': "(neutral)",
                        }}
                        autoComplete="sex operational"
                        onKeyPress={(event) => {
                          if (event.key === 'f') {
                            setValue('ansprechpartner_geschaeftsleitung_anrede', 'frau');
                          } else if (event.key === 'h') {
                            setValue('ansprechpartner_geschaeftsleitung_anrede', 'herr');
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Titel"
                        name="ansprechpartner_geschaeftsleitung_titel"
                        register={register}
                        errors={errors}
                        variant="outlined"
                        disabled={isSubmitting}
                        autoComplete="honorific-prefix operational"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <AccountBox/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.ansprechpartner_geschaeftsleitung_name}
                  label="Name"
                  name="ansprechpartner_geschaeftsleitung_name"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="name operational"
                />
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <AlternateEmail/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.ansprechpartner_geschaeftsleitung_email}
                  label="E-Mail"
                  name="ansprechpartner_geschaeftsleitung_email"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="email operational"
                />
              )}
            />
          </ListItem>

          <ListSubheader>Ansprechpartner Technik / Musikredaktion</ListSubheader>

          <ListItem>
            <ListItemIcon/>
            <ListItemText
              primary={(
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Choices
                        name="ansprechpartner_technik_anrede"
                        label="Anrede"
                        errors={errors}
                        control={control}
                        items={{
                          frau: "Frau",
                          herr: "Herr",
                          '': "(neutral)",
                        }}
                        autoComplete="sex technical"
                        onKeyPress={(event) => {
                          if (event.key === 'f') {
                            setValue('ansprechpartner_technik_anrede', 'frau');
                          } else if (event.key === 'h') {
                            setValue('ansprechpartner_technik_anrede', 'herr');
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Titel"
                        name="ansprechpartner_technik_titel"
                        register={register}
                        errors={errors}
                        variant="outlined"
                        disabled={isSubmitting}
                        autoComplete="honorific-prefix technical"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Person/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.ansprechpartner_technik_name}
                  label="Name"
                  name="ansprechpartner_technik_name"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="name technical"
                />
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <AlternateEmail/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.ansprechpartner_technik_email}
                  label="E-Mail"
                  name="ansprechpartner_technik_email"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="email technical"
                />
              )}
            />
          </ListItem>

          <ListSubheader>Ansprechpartner für LIRA</ListSubheader>

          <ListItem>
            <ListItemIcon/>
            <ListItemText
              primary={(
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Choices
                        name="ansprechpartner_lira_anrede"
                        label="Anrede"
                        errors={errors}
                        control={control}
                        items={{
                          frau: "Frau",
                          herr: "Herr",
                          '': "(neutral)",
                        }}
                        autoComplete="sex lira"
                        onKeyPress={(event) => {
                          if (event.key === 'f') {
                            setValue('ansprechpartner_lira_anrede', 'frau');
                          } else if (event.key === 'h') {
                            setValue('ansprechpartner_lira_anrede', 'herr');
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Titel"
                        name="ansprechpartner_lira_titel"
                        register={register}
                        errors={errors}
                        variant="outlined"
                        disabled={isSubmitting}
                        autoComplete="honorific-prefix lira"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <AccountBalanceWallet/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.ansprechpartner_lira_name}
                  label="Name"
                  name="ansprechpartner_lira_name"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="name lira"
                />
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <AlternateEmail/>
            </ListItemIcon>
            <ListItemText
              primary={(
                <TextField
                  ref={focusableFields.ansprechpartner_lira_email}
                  label="E-Mail"
                  name="ansprechpartner_lira_email"
                  register={register}
                  errors={errors}
                  variant="outlined"
                  disabled={isSubmitting}
                  autoComplete="email lira"
                />
              )}
            />
          </ListItem>

          <Collapse in={hasErrors}>
            <ListItem>
              <ListItemIcon/>
              <ListItemText primary={(
                <Alert severity="error" variant="filled" icon={hasErrors ? undefined : false}>
                  {hasErrors ? (
                    errors?.non_field_errors?.message || errors?.detail?.message || "Bitte korrigieren Sie Ihre Angaben."
                  ) : <>&nbsp;</>}
                </Alert>
              )}
              />
            </ListItem>
          </Collapse>

          <ListItem>
            <ListItemIcon/>
            <ListItemText
              style={{textAlign: 'right'}}
              primary={(
                <>
                  {!isSubmitting ? (
                    <Button
                      onClick={cancel}
                      disabled={isSubmitting}
                    >
                      Nicht speichern
                    </Button>
                  ) : null}
                  <> </>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress size="1rem"/>&nbsp;Angaben werden gespeichert...
                      </>
                    ) : (
                      "Speichern"
                    )}
                  </Button>
                </>
              )}
            />
          </ListItem>
        </List>
      </form>
    </FormProvider>
  );
}
