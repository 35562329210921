import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {REGISTER_USER_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {Box, DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {USER} from "src/api/api-schemas";
import {preferTokenLoginForm} from "src/features/ui/preferences";
import {useDispatch} from "react-redux";
import {Alert, AlertTitle} from "@material-ui/lab";
import {updateProfile} from "src/features/participation";
import {selectOrganization} from "src/features/dashboard";

export function RegisterUserForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(USER);

  const dispatch = useDispatch();

  const createUser = async (validatedData) => {
    const {organization} = validatedData;

    await entityApi.post(
      `/api/sendemeldung/organizations/${organization}/users/`,
      validatedData,
      {createEntities: true, organization},
    );

    dispatch(preferTokenLoginForm(false));
    dispatch(updateProfile({has_credentials: true, has_just_set_up_credentials: true}));
    dispatch(selectOrganization({organization}));

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'register-user'}
      title={<>Benutzername &amp; Passwort festlegen</>}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={createUser}
      schema={REGISTER_USER_SCHEMA}
      {...props}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Alert variant="filled" severity="info" color="warning">
                <AlertTitle>Wozu?</AlertTitle>
                <p>
                  Über den bestehenden Zugangscode hat Ihr Unternehmen schnell und unkompliziert
                  Zugriff auf vertragsbezogene Daten und auf unsere Testumgebung erhalten.
                </p>
                <p>
                  Für den anstehenden Produktivbetrieb müssen Sie Ihr Passwort aus Sicherheitsgründen ändern.
                  Auch haben Sie bald die Möglichkeit, mehreren Personen gezielt Zugriff zu gewähren.
                </p>
                <p>
                  Um diese Möglichkeiten vorzubereiten, möchten wir Sie bitten, ein Benutzerkonto anzulegen. Bitte
                  geben Sie dazu Ihre E-Mail-Adresse ein und wählen Sie ein Passwort.
                </p>
                <p>
                  Zukünftig können Sie sich dann mit diesen Daten einloggen. Ihr ursprünglicher Zugangscode wird nach
                  erfolgter Benutzereinrichtung zu Ihrer Sicherheit deaktiviert.
                </p>
              </Alert>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <YupField name="email"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="password"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="passwordConfirmation"/>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Alert variant="filled" severity="info">
                <AlertTitle>Tipp</AlertTitle>
                <p>
                  Sollten Sie für mehrere Sendeunternehmen verantwortlich sein und über mehrere Zugangscodes verfügen,
                  können Sie ein gemeinsames Benutzerkonto anlegen. Geben Sie hierzu einfach jeweils die gleichen
                  Zugangsdaten ein.
                </p>
              </Alert>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
