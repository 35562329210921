import {Avatar, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";

export default function SkeletonList({items = 1, showSecondary}) {
  return (
    <List>
      {[...Array(items)].map((_, i) => (
        <ListItem key={i}>
          <ListItemIcon>
            <Skeleton variant="circle">
              <Avatar><CheckIcon/></Avatar>
            </Skeleton>
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton variant="text"/>}
            secondary={showSecondary ? <Skeleton variant="text"/> : undefined}
          />
        </ListItem>
      ))}
    </List>
  );
}
