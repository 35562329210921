import MuiTextField from "@material-ui/core/TextField";
import React from "react";
import _ from "lodash";

export default React.forwardRef(
  ({errors = {}, name, variant = 'outlined', fullWidth = true, register, ...props}, ref) => {
    const {ref: inputRef, ...rest} = register(name);

    const error = _.get(errors, name);

    return (
      <MuiTextField
        ref={ref}
        name={name}
        inputRef={inputRef}
        {...rest}
        {...props}
        variant={variant}
        fullWidth={fullWidth}
        error={!!error}
        helperText={error?.message}
      />
    );
  },
);
