import UpdateContactDetails from "./update-contact-details-view";
import {connect} from "react-redux";
import {getProfile} from "src/features/participation";
import {getProgress, resetProgress} from "src/features/ui/progress";

const mapStateToProps = state => {
  const data = getProfile(state);

  const {inProgress: isSubmitting, formErrors} = getProgress('profileUpdate', 'profileUpdate')(state);

  return {
    data,
    isSubmitting,
    formErrors,
  };
};

const mapDispatchToProps = (dispatch, {close}) => {
  return {
    cancel: () => {
      dispatch(resetProgress({type: 'profileUpdate', id: 'profileUpdate'}));
      close();
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateContactDetails);
