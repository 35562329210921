import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Fab,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import {AccountBalance, Check, Edit, EuroSymbol, HourglassEmpty, NotInterested, VpnKey} from "@material-ui/icons";
import React, {useState} from "react";
import SkeletonList from "src/components/welcome/components/skeleton-list-view";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Unshrinkable} from "src/packages/unshrinkable";
import {navigate} from "gatsby";
import {RegisterUserForm} from "src/components/entities/person/RegisterUserForm";
import {useSelector} from "react-redux";
import {getProfile} from "src/features/participation";

export default function Participation(
  {
    loaded,
    willParticipate,
    wontParticipate,
    hasPaid,
    takePart,
    doNotTakePart,
    revertDecision,
    isSubmitting,
    updateError,
  }
) {
  const [registerUser, setRegisterUser] = useState(null);

  const {
    organization,
    ansprechpartner_technik_email,
    ansprechpartner_geschaeftsleitung_email,
    has_credentials: hasCredentials,
    has_just_set_up_credentials: hasJustSetUpCredentials,
  } = useSelector(getProfile);
  const suggestedEmail = ansprechpartner_technik_email || ansprechpartner_geschaeftsleitung_email;

  return (
    <>
      {!loaded ? (
        <SkeletonList items={2} showSecondary/>
      ) : (
        <>
          <List>
            <Unshrinkable Component={ListItem} width={false} height={true} style={{minHeight: 80}}>
              {isSubmitting ? (
                <ListItemIcon>
                  <CircularProgress size='1.5rem'/>
                </ListItemIcon>
              ) : willParticipate ? (
                <ListItemIcon>
                  <Check/>
                </ListItemIcon>
              ) : wontParticipate ? (
                <ListItemIcon>
                  <NotInterested/>
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <AccountBalance/>
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  isSubmitting ? (
                    <Typography color="textSecondary">
                      Teilnahme wird aktualisiert...
                    </Typography>
                  ) : hasPaid ? (
                    "Ihr Haus nimmt an der Umlage teil."
                  ) : willParticipate ? (
                    "Ihr Haus nimmt an der Umlage teil."
                  ) : wontParticipate ? (
                    <>Ihr Haus nimmt <strong>nicht</strong> an der Umlage teil.</>
                  ) : (
                    <>
                      <span>Ihr Haus wird den Dienst nutzen: </span>
                      <span style={{whiteSpace: 'nowrap'}}>
                        <Grow in appear>
                        <Chip
                          label="ja"
                          variant="outlined"
                          clickable
                          color="primary"
                          onClick={takePart}
                        />
                      </Grow>
                      <span> / </span>
                      <Grow in appear>
                        <Chip
                          label="nein"
                          variant="outlined"
                          clickable
                          color="primary"
                          onClick={doNotTakePart}
                        />
                      </Grow>
                      </span>
                    </>
                  )
                }
                secondary={
                  isSubmitting ? (
                    null
                  ) : hasPaid ? (
                    "Danke für Ihre Unterstützung!"
                  ) : ((willParticipate || wontParticipate) && revertDecision) ? (
                    null
                  ) : (takePart || doNotTakePart) ? (
                    <>Beim Klick auf <i>ja</i> merken wir Ihre Beteiligung vor.</>
                  ) : null
                }
              />
              {isSubmitting ? (
                null
              ) : hasPaid ? (
                null
              ) : (willParticipate || wontParticipate) && revertDecision ? (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={revertDecision}>
                    <Edit/>
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </Unshrinkable>
            <Collapse in={!!updateError}>
              <ListItem>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary={
                  <Alert variant="outlined" severity="error">
                    {updateError}
                  </Alert>
                }/>
              </ListItem>
            </Collapse>
            <Collapse in={!wontParticipate}>
              <ListItem>
                {hasPaid ? (
                  <ListItemIcon>
                    <Check/>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <EuroSymbol/>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    hasPaid ? (
                      "Wir haben Ihre Zahlung erhalten."
                    ) : willParticipate ? (
                      "Ihre Zahlung steht noch aus."
                    ) : (
                      "Sie haben bislang nichts bezahlt."
                    )
                  }
                  secondary={
                    hasPaid ? (
                      ""
                    ) : (
                      "Bitte überweisen Sie Ihren Anteil an der Projektfinanzierung, um Ihre Teilnahme zu sichern."
                    )
                  }
                />
                {hasPaid ? (
                  null
                ) : willParticipate ? (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" disabled>
                      <HourglassEmpty color="primary"/>
                    </IconButton>
                  </ListItemSecondaryAction>
                ) : (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" disabled>
                      <HourglassEmpty/>
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </Collapse>
            <Collapse in={hasPaid}>
              <ListItem>
                {hasCredentials ? (
                  <ListItemIcon>
                    <Check/>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <VpnKey/>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    hasCredentials ? (
                      "Ihre Zugangsdaten sind eingerichtet."
                    ) : (
                      "Sie haben noch kein Passwort eingerichtet."
                    )
                  }
                  secondary={
                    hasCredentials ? (
                      ""
                    ) : (
                      "Bitte legen Sie persönliche Zugangsdaten fest, um Ihren Zugang abzusichern."
                    )
                  }
                />
                {hasCredentials ? (
                  null
                ) : (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" disabled>
                      <HourglassEmpty/>
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <Collapse in={hasPaid && organization && (!hasCredentials || hasJustSetUpCredentials)}>
                <Collapse in={!hasCredentials}>
                  <Box mt={1} mb={2} align="center">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setRegisterUser({organization, username: suggestedEmail, email: suggestedEmail})}>
                      Benutzername &amp; Passwort festlegen
                    </Button>
                  </Box>
                </Collapse>
                <Collapse in={hasCredentials && hasJustSetUpCredentials}>
                  <Box mt={1} mb={2}>
                    <Alert variant="filled" severity="success" color="warning">
                      <AlertTitle>Super!</AlertTitle>
                      Beim nächsten Login können Sie Ihre gerade festgelegten Zugangsdaten verwenden.
                    </Alert>
                  </Box>
                </Collapse>
              </Collapse>
            </Collapse>
          </List>

          <Collapse in={hasPaid && !hasCredentials}>
            <Box mt={2}>
              <Alert variant="standard" severity="success" color="warning">
                <AlertTitle>Zugriff auf Plattform erhalten</AlertTitle>
                <p>
                  Die angekündigten Testwochen sind gestartet!
                </p>
                <p>
                  Wie wir in unserem Schreiben vom 13.07.2021 mitgeteilt haben, ist es aus Sicherheitsgründen
                  erforderlich, ein eigenes Passwort zu vergeben, um auf unsere Plattform zuzugreifen.
                </p>
                <p>
                  Bitte legen Sie jetzt Ihre Zugangsdaten fest.
                </p>
                <Box mt={2} mb={2} align="center">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setRegisterUser({organization, username: suggestedEmail, email: suggestedEmail})}>
                      Benutzername &amp; Passwort festlegen
                    </Button>
                </Box>
              </Alert>
            </Box>
          </Collapse>

          <Collapse in={hasPaid && hasCredentials}>
            <Box mt={2}>
              <Alert variant="outlined" severity="success" color="info">
                <AlertTitle>Neues Dashboard</AlertTitle>
                <p>
                  Sie können jetzt zu unserem neuen Dashboard wechseln, um die Verarbeitung Ihrer Sendemeldungen
                  zu testen.
                </p>
                <Box mt={2} align="center">
                  <Button
                    color="primary"
                    onClick={typeof window !== "undefined" ? (
                      () => window.location.href = '/dashboard'
                    ) : (
                      () => navigate('/dashboard')
                    )}
                  >
                    zum neuen Dashboard wechseln
                  </Button>
                </Box>
              </Alert>
            </Box>
          </Collapse>

          <Collapse in={wontParticipate && !isSubmitting}>
            <Alert variant="outlined" severity="info" color="warning">
              <AlertTitle>Tipp</AlertTitle>
              <p>Auch wenn Sie derzeit keine Sendemeldungen abliefern müssen, können Sie sich durch Ihre
                Teilnahme den späteren Zugang zur Plattform &ndash; ohne Einrichtungsgebühr &ndash; sichern.</p>
              <Box align="center" mb={1}>
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="add"
                  onClick={takePart}
                >
                  Doch teilnehmen!
                </Fab>
              </Box>
            </Alert>
          </Collapse>
        </>
      )}

      {registerUser ? (
        <RegisterUserForm
          data={registerUser}
          onClose={() => setRegisterUser(null)}
        />
      ) : null}
    </>
  );
}
