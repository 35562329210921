import Downloads from "./downloads-view";
import {connect} from "react-redux";
import {getSessionToken} from "src/features/session";
import {getProfile} from "src/features/participation";

const mapStateToProps = state => {
  const {documents} = getProfile(state);

  return {
    documents,
    sessionToken: getSessionToken(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Downloads);
