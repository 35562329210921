import {connect} from "react-redux";
import {getProfile, performProfileUpdate} from "src/features/participation";
import _ from "lodash";
import {getProgress} from "src/features/ui/progress";
import ContactDetails from "./contact-details-view";

const mapStateToProps = (state) => {
  const profile = getProfile(state);

  const profileUpdateProgress = getProgress('profileUpdate', 'profileUpdate')(state);

  return {
    ...profile,
    updatingProfile: profileUpdateProgress?.inProgress || profileUpdateProgress?.error,
    loaded: !_.isEmpty(profile),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (profile) => dispatch(performProfileUpdate({id: 'profileUpdate', profile})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactDetails);
