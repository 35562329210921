import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import {Box, Collapse, Hidden, Paper, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import {Check, HourglassEmpty, QueryBuilder} from "@material-ui/icons";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import HrefButton from "src/packages/gatsby-mui-helpers/HrefButton";

const useStyles = makeStyles({
  paper: {
    padding: '6px 16px',
  },
  date: {
    paddingTop: '10px',
  },
});


function ProjectTimelineItem({date, done, active, future, title, description}) {
  const classes = useStyles();

  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TimelineItem>
      <TimelineOppositeContent style={narrow ? {flex: 0} : null}>
        <Hidden smDown>
          <Typography variant="body2" color="textSecondary" className={classes.date}>
            {date}
          </Typography>
        </Hidden>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {done ? (
          <TimelineDot color="inherit">
            <Check/>
          </TimelineDot>
        ) : active ? (
          <TimelineDot color="primary">
            <HourglassEmpty/>
          </TimelineDot>
        ) : (
          <TimelineDot>
            <QueryBuilder/>
          </TimelineDot>
        )}
        <TimelineConnector/>
      </TimelineSeparator>
      <TimelineContent>
        <Hidden mdUp>
          <Box my={1.25}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {date}
            </Typography>
          </Box>
        </Hidden>
        {future ? (
          <Box my={0.5}>
            <Typography variant="h6" component="h1" color="textSecondary">
              {title}
            </Typography>
            <Typography color="textSecondary">
              {description}
            </Typography>
          </Box>
        ) : done ? (
          <Box my={0.5}>
            <Typography variant="h6" component="h1">
              {title}
            </Typography>
            <Typography>
              {description}
            </Typography>
          </Box>
        ) : active ? (
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1" color="primary">
              {title}
            </Typography>
            <Typography>
              {description}
            </Typography>
          </Paper>
        ) : (
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              {title}
            </Typography>
            <Typography>
              {description}
            </Typography>
          </Paper>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}


export default function ProjectTimeline({showFuture = true}) {
  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Timeline align={narrow ? 'left' : 'alternate'}>
      <ProjectTimelineItem
        done
        date="21.12.2020"
        title="Machbarkeitsstudie"
        description="Die von der APR beauftragte Machbarkeitsstudie wurde erfolgreich abgeschlossen."
      />
      <ProjectTimelineItem
        done
        date="24.02.2021"
        title="Projektstart"
        description="Rahmenvertrag ist unterzeichnet."
      />
      <ProjectTimelineItem
        done
        date={
          <>
            24.02.2021 &ndash; 31.03.2021
          </>
        }
        title="Finanzierung"
        description={<>Die Verbandsmitglieder von APR und VAUNET haben die Finanzierung sichergestellt.</>}
      />
      <Collapse in={true}>
        {/* For any reason, we need a dummy item here for alternation to work. */}
        <Collapse in={false}><TimelineItem/></Collapse>

        <ProjectTimelineItem
          done
          date="seit 01.04.2021"
          title="Testphase GVL"
          description="Durchführung erster Tests mit GVL gemäß Vereinbarung"
        />
        <ProjectTimelineItem
          done
          date="seit 23.04.2021"
          title="Entwicklervorschau"
          description="Teilnehmende Sender haben Zugang zur frühen Entwicklungsversion für Tests / Feedback."
        />
        <ProjectTimelineItem
          done
          date="18.05.2021"
          title="Datenübermittlungsvertrag"
          description="Mit der GEMA wurde ein Vertrag für zukünftige Datenübermittlung abgeschlossen. Von der GVL liegen bereits Datenbanken vor. Die Integration in die Plattform erfolgt im Laufe der zweiten Jahreshälfte."
        />
        <ProjectTimelineItem
          done
          date="21.07.2021"
          title="Webinar"
          description={
            <>
              In einem gemeinsamen Webinar mit APR und VAUNET informieren wir über die nächsten Schritte zur Einführung
              der XML-Schnittstelle.
              <p>
                <HrefButton
                  href="https://www.privatfunk.de/r21/10158.html"
                  target="_blank"
                  variant="outlined"
                  noOnClick
                >
                  Webinar ansehen
                </HrefButton>
              </p>
            </>
          }
        />
        <ProjectTimelineItem
          done
          date={<>seit 16.08.2021</>}
          title="Testwochen"
          description={
            <>
              Um einen reibungslosen Übergang zu gewährleisten, sollten von jedem Sende&shy;unternehmen Beispieldaten
              für den Monat Juli 2021 bereitgestellt werden, die durch Sendemeldung.de konvertiert und für
              GEMA / GVL zur Prüfung freigegeben wurden.
            </>
          }
        />
        <ProjectTimelineItem
          active
          date="seit 22.09.2021"
          title="Vorläufiger Betrieb"
          description={
            <>
              <p>
                Plattform kann mit einge&shy;schränk&shy;tem Funktions&shy;umfang genutzt,
                offizielle <strong>GEMAGVL-XML</strong>-Sendemeldungen können an GEMA / GVL übermittelt werden.
              </p>
              <p>
                Voraussetzung für die Übermittlung von Produktiv&shy;meldungen ist die vorherige Freigabe des
                Sende&shy;unternehmens durch GEMA/GVL nach Übermittlung einer Beispiel-Sendemeldung (siehe Testwochen).
              </p>
              <p>
                Die ersten Freigaben liegen bereits vor.
                Sendeunternehmen, die noch nicht an den Tests teilgenommen haben, können dies nachholen.
              </p>
              <p>
                <strong>Hinweis:</strong> Sie können Ihre Sendemeldungen <strong>fristgerecht</strong> über
                Sendemeldung.de melden.
                Im XML-Format müssen erstmalig die Ausstrahlungen ab 1. Juli gemeldet werden; die Meldefrist beträgt
                2 Monate ab Ende des Ausstrahlungsmonats. Ihre Meldungen aus Juli können also bis Ende September
                gemeldet werden.
                Sollten Sie Ihre Freigabe durch GEMA/GVL erst nach Ende September erhalten, genügt zur Fristwahrung
                die rechtzeitige Übermittlung einer Beispiel-Sendemeldung.
              </p>
            </>
          }
        />
        <ProjectTimelineItem
          future
          date="voraussichtlich ab Januar 2022"
          title="Produktivbetrieb"
          description="Die Plattform ist in vollem Umfang nutzbar; externe Datenquellen stehen zur Vervollständigung von Sendemeldungen zur Verfügung."
        />
      </Collapse>
    </Timeline>
  );
}
