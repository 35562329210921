import {
  Chip,
  Collapse,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import {
  AccountBalanceWallet,
  AccountBox,
  AlternateEmail,
  Business,
  Edit,
  Info,
  Person,
  PersonAdd
} from "@material-ui/icons";
import React, {useMemo, useState} from "react";
import SkeletonList from "src/components/welcome/components/skeleton-list-view";
import {UpdateContactDetails} from "src/components/welcome/components/index";
import _ from 'lodash';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: 'inline-block',
    margin: -theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));


export default function ContactDetails(
  {
    loaded,
    customer_id,
    firmenname,
    adresse,
    ansprechpartner_geschaeftsleitung_anrede,
    ansprechpartner_geschaeftsleitung_titel,
    ansprechpartner_geschaeftsleitung_name,
    ansprechpartner_geschaeftsleitung_email,
    ansprechpartner_technik_anrede,
    ansprechpartner_technik_titel,
    ansprechpartner_technik_name,
    ansprechpartner_technik_email,
    ansprechpartner_lira_anrede,
    ansprechpartner_lira_titel,
    ansprechpartner_lira_name,
    ansprechpartner_lira_email,
    updateProfile,
    updatingProfile
  }
) {
  const displayAddress = adresse?.replace(/\r?\n/g, ', ');

  const [update, setUpdate] = useState(false);

  const makeEditProfile = useMemo(() => _.memoize(field => () => {
    setUpdate(true);
    setFocusField(field);
  }), [setUpdate])

  const [focusField, setFocusField] = useState();

  const hideEditProfile = useMemo(() => () => {
    setUpdate(false);
    setFocusField(null);
  }, [setUpdate, setFocusField])

  const showUpdateForm = update || updatingProfile;

  const classes = useStyles();

  return (
    <>
      {!loaded ? (
        <SkeletonList items={2} showSecondary/>
      ) : (
        <>
          <Collapse in={showUpdateForm}>
            <UpdateContactDetails
              focusField={focusField}
              saveProfile={updateProfile}
              close={hideEditProfile}
              visible={showUpdateForm}
            />
          </Collapse>
          <Collapse in={!showUpdateForm}>
            <List>
              <Collapse in={!!(firmenname || displayAddress)}>
                <ListItem>
                  <ListItemIcon>
                    <Business/>
                  </ListItemIcon>
                  <ListItemText
                    primary={firmenname}
                    secondary={displayAddress}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="edit" onClick={makeEditProfile('firmenname')}>
                      <Edit/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Collapse>

              <Collapse in={!!(ansprechpartner_geschaeftsleitung_name || ansprechpartner_geschaeftsleitung_email)}>
                <ListItem>
                  <ListItemIcon>
                    <AccountBox/>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {ansprechpartner_geschaeftsleitung_name ? (
                          <>
                            {ansprechpartner_geschaeftsleitung_anrede === 'herr' ? (
                              "Herr "
                            ) : ansprechpartner_geschaeftsleitung_anrede === 'frau' ? (
                              "Frau "
                            ) : null}
                            {ansprechpartner_geschaeftsleitung_titel}{' '}
                            {ansprechpartner_geschaeftsleitung_name}
                            <br/>
                          </>
                        ) : null}
                        {ansprechpartner_geschaeftsleitung_email}
                      </>
                    }
                    secondary="Ansprechpartner Geschäftsleitung"
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={makeEditProfile('ansprechpartner_geschaeftsleitung_name')}>
                      <Edit/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Collapse>

              <Collapse
                in={
                  !!(
                    ansprechpartner_geschaeftsleitung_name &&
                    !ansprechpartner_geschaeftsleitung_email &&
                    (ansprechpartner_technik_name || ansprechpartner_technik_email ||
                      ansprechpartner_lira_name || ansprechpartner_lira_email)
                  )
                }
              >
                <ListItem>
                  <ListItemIcon/>
                  <ListItemText
                    primary={
                      <span className={classes.chipContainer}>
                        <Grow in={!ansprechpartner_geschaeftsleitung_email}>
                          <Chip
                            icon={<AlternateEmail/>}
                            label="E-Mail ergänzen"
                            variant="outlined"
                            clickable
                            color="primary"
                            onClick={makeEditProfile('ansprechpartner_geschaeftsleitung_email')}
                          />
                        </Grow>
                      </span>
                    }
                  />
                </ListItem>
              </Collapse>

              <Collapse in={!!(ansprechpartner_technik_name || ansprechpartner_technik_email)}>
                <ListItem>
                  <ListItemIcon>
                    <Person/>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {ansprechpartner_technik_name ? (
                          <>
                            {ansprechpartner_technik_anrede === 'herr' ? (
                              "Herr "
                            ) : ansprechpartner_technik_anrede === 'frau' ? (
                              "Frau "
                            ) : null}
                            {ansprechpartner_technik_titel}{' '}
                            {ansprechpartner_technik_name}
                            <br/>
                          </>
                        ) : null}
                        {ansprechpartner_technik_email}
                      </>
                    }
                    secondary="Ansprechpartner Technik / Musikredaktion"
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={makeEditProfile('ansprechpartner_technik_name')}
                    >
                      <Edit/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Collapse>

              <Collapse
                in={
                  !!(
                    ansprechpartner_technik_name &&
                    !ansprechpartner_technik_email &&
                    (ansprechpartner_lira_name || ansprechpartner_lira_email)
                  )
                }
              >
                <ListItem>
                  <ListItemIcon/>
                  <ListItemText
                    primary={
                      <span className={classes.chipContainer}>
                        <Grow in={!ansprechpartner_technik_email}>
                          <Chip
                            icon={<AlternateEmail/>}
                            label="E-Mail ergänzen"
                            variant="outlined"
                            clickable
                            color="primary"
                            onClick={makeEditProfile('ansprechpartner_technik_email')}
                          />
                        </Grow>
                      </span>
                    }
                  />
                </ListItem>
              </Collapse>

              <Collapse in={!!(ansprechpartner_lira_name || ansprechpartner_lira_email)}>
                <ListItem>
                  <ListItemIcon>
                    <AccountBalanceWallet/>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {ansprechpartner_lira_name ? (
                          <>
                            {ansprechpartner_lira_anrede === 'herr' ? (
                              "Herr "
                            ) : ansprechpartner_lira_anrede === 'frau' ? (
                              "Frau "
                            ) : null}
                            {ansprechpartner_lira_titel}{' '}
                            {ansprechpartner_lira_name}
                            <br/>
                          </>
                        ) : null}
                        {ansprechpartner_lira_email}
                      </>
                    }
                    secondary="Ansprechpartner für LIRA"
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={makeEditProfile('ansprechpartner_lira_name')}
                    >
                      <Edit/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Collapse>

              <Collapse
                in={
                  !!(ansprechpartner_lira_name && !ansprechpartner_lira_email) ||
                  !!(!ansprechpartner_lira_name && !ansprechpartner_lira_email && ansprechpartner_technik_name && !ansprechpartner_technik_email) ||
                  !!(!ansprechpartner_lira_name && !ansprechpartner_lira_email && !ansprechpartner_technik_name && !ansprechpartner_technik_email && ansprechpartner_geschaeftsleitung_name && !ansprechpartner_geschaeftsleitung_email) ||
                  (!ansprechpartner_geschaeftsleitung_email && !ansprechpartner_geschaeftsleitung_name) ||
                  (!ansprechpartner_technik_email && !ansprechpartner_technik_name) ||
                  (!ansprechpartner_lira_email && !ansprechpartner_lira_name)
                }
              >
                <ListItem>
                  <ListItemIcon/>
                  <ListItemText
                    primary={
                      <span className={classes.chipContainer}>
                        {(
                          !!(ansprechpartner_lira_name && !ansprechpartner_lira_email) ||
                          !!(!ansprechpartner_lira_name && !ansprechpartner_lira_email && ansprechpartner_technik_name && !ansprechpartner_technik_email) ||
                          !!(!ansprechpartner_lira_name && !ansprechpartner_lira_email && !ansprechpartner_technik_name && !ansprechpartner_technik_email && ansprechpartner_geschaeftsleitung_name && !ansprechpartner_geschaeftsleitung_email)
                        ) ? (
                          <Grow in={true}>
                            <Chip
                              icon={<AlternateEmail/>}
                              label="E-Mail ergänzen"
                              variant="outlined"
                              clickable
                              color="primary"
                              onClick={
                                makeEditProfile(
                                  (ansprechpartner_lira_name && !ansprechpartner_lira_email) ? (
                                    'ansprechpartner_lira_email'
                                  ) : (ansprechpartner_technik_name && !ansprechpartner_technik_email) ? (
                                    'ansprechpartner_technik_email'
                                  ) : (
                                    'ansprechpartner_geschaeftsleitung_name'
                                  ))
                              }
                            />
                          </Grow>
                        ) : null}
                        <Grow appear in={(
                          (!ansprechpartner_geschaeftsleitung_email && !ansprechpartner_geschaeftsleitung_name) ||
                          (!ansprechpartner_technik_email && !ansprechpartner_technik_name) ||
                          (!ansprechpartner_lira_email && !ansprechpartner_lira_name)
                        )}>
                          <Chip
                            icon={<PersonAdd/>}
                            label="Ansprechpartner ergänzen"
                            variant="outlined"
                            clickable
                            color="primary"
                            onClick={
                              makeEditProfile(
                                (!ansprechpartner_geschaeftsleitung_email && !ansprechpartner_geschaeftsleitung_name) ? (
                                  'ansprechpartner_geschaeftsleitung_name'
                                ) : (!ansprechpartner_technik_email && !ansprechpartner_technik_name) ? (
                                  'ansprechpartner_technik_name'
                                ) : (
                                  'ansprechpartner_lira_name'
                                ))
                            }
                          />
                        </Grow>
                      </span>
                    }
                  />
                </ListItem>
              </Collapse>

              <Collapse in={!!customer_id}>
                <ListItem>
                  <ListItemIcon>
                    <Info/>
                  </ListItemIcon>
                  <ListItemText
                    primary={<>Ihre Kundennummer lautet <strong>{customer_id}</strong>.</>}
                    secondary={"Bitte geben Sie diese bei Rückfragen an."}
                  />
                </ListItem>
              </Collapse>
            </List>
          </Collapse>
        </>
      )}
    </>
  );
}
