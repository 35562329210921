import * as yup from "yup";
import yupLocaleDe from 'src/packages/yup-locale-de';

yup.setLocale(yupLocaleDe);

export const PARTICIPATION_LOGIN_SCHEMA = yup.object().shape({
  accessToken: yup.string().trim()
    .required(''),
});

export const PROFILE_SCHEMA = yup.object().shape({
  firmenname: yup.string().trim().required('Dies ist ein Pflichtfeld.').max(255),
  adresse: yup.string().trim().required('Dies ist ein Pflichtfeld.').max(255),

  ansprechpartner_geschaeftsleitung_anrede: yup.string().trim().max(255).nullable(),
  ansprechpartner_geschaeftsleitung_titel: yup.string().trim().max(255).nullable(),
  ansprechpartner_geschaeftsleitung_name: yup.string().trim().max(255).nullable(),
  ansprechpartner_geschaeftsleitung_email: yup.string().email('Dieses Feld muss eine gültige E-Mail-Adresse enthalten.').max(255).nullable(),

  ansprechpartner_technik_anrede: yup.string().trim().max(255).nullable().nullable(),
  ansprechpartner_technik_titel: yup.string().trim().max(255).nullable(),
  ansprechpartner_technik_name: yup.string().trim().max(255).nullable(),
  ansprechpartner_technik_email: yup.string().email('Dieses Feld muss eine gültige E-Mail-Adresse enthalten.').max(255).nullable(),

  ansprechpartner_lira_anrede: yup.string().trim().max(255).nullable(),
  ansprechpartner_lira_titel: yup.string().trim().max(255).nullable(),
  ansprechpartner_lira_name: yup.string().trim().max(255).nullable(),
  ansprechpartner_lira_email: yup.string().email('Dieses Feld muss eine gültige E-Mail-Adresse enthalten.').max(255).nullable(),
});
